window.addEventListener('load', function(){

    /****************************************
    * Configuration for Blog's Slider
    *****************************************/
     new Glider(document.querySelector('.blog').querySelector('.glider'), {
        slidesToShow: 1,
        slidesToScroll: 1,
        duration: .5,
        dots: document.querySelector('.blog').querySelector('.dots'),
        draggable: true,
        dragVelocity: 3.3,
        easing: function (x, t, b, c, d) {
            return c*(t/=d)*t + b;
        },
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    draggable: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    dots: false,
                    arrows: {
                        prev: document.querySelector('.blog').querySelector('.glider-prev'),
                        next: document.querySelector('.blog').querySelector('.glider-next'),
                    }
                }
            },
        ]
    })

    /****************************************
    * Configuration for Articles's Slider
    *****************************************/
    new Glider(document.querySelector('.artigos').querySelector('.glider'), {
        slidesToShow: 1,
        slidesToScroll: 1,
        duration: .5,
        dots: document.querySelector('.artigos').querySelector('.dots'),
        draggable: true,
        dragVelocity: 3.3,
        easing: function (x, t, b, c, d) {
            return c*(t/=d)*t + b;
        },
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    draggable: false,
                    arrows: {
                        prev: document.querySelector('.artigos').querySelector('.glider-prev'),
                        next: document.querySelector('.artigos').querySelector('.glider-next'),
                    }
                }
            },
        ]
    })
})